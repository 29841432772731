import React from 'react'
import { config, useSpring } from 'react-spring'
import Layout from '../components/layout'
import { AnimatedBox } from '../elements'
import SEO from '../components/SEO'
import Contact from '../components/contact'

const About = () => {
  const pageAnimation = useSpring({
    config: config.slow,
    from: { opacity: 0 },
    to: { opacity: 1 },
  })

  return (
    <Layout color="#f4f4f4">
      <SEO title="Hakkımızda | Last Stop Park Caddesi" desc="Merhaba. Last Stop websitesine hoşgeldiniz!" />
      <AnimatedBox style={pageAnimation} py={[6, 6, 6, 8]} px={[6, 6, 8, 6, 8, 13]}>
        <h1>Merhaba!</h1>
        <p>2005 yılında Ankara Bahçelievler'de başlayan yolculuğumuz, Çayyolu Park Caddesi'nde devam ediyor.</p>
        <p>
          Tavuktan kırmızı ete, kokoreçten mezelere, en sağlıklı salatalardan, en lezzetli tatlılara kadar uzanan
          menümüz ile siz misafirlerimizi ağırlamaktan mutluluk duyuyoruz.
        </p>
        <br />
        <h1>Bize ulaşın</h1>
        <Contact />
        <br />
        <p>
          Tel: <a href="tel:+903122420135">0312 242 01 35</a> veya <a href="tel:+905330155856">0533 015 58 56</a>
        </p>
        <p>
          Adres:{' '}
          <a href="https://g.page/ttlaststop?share">
            Prof. Dr. Ahmet Taner Kışlalı Mah. 2864. Cad. No:38/C <br />
            Park Caddesi, Çayyolu, Ankara
          </a>
        </p>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3062.184219648171!2d32.678198315379404!3d39.870111279432415!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d338d1a47188d7%3A0x5f30a38db328920f!2sThis%20Town&#39;s%20Last%20Stop!5e0!3m2!1sen!2sie!4v1591366518051!5m2!1sen!2sie"
          width="100%"
          height="300"
          frameBorder="0"
          aria-hidden="false"
          title="Last Stop Adres"
        />
      </AnimatedBox>
    </Layout>
  )
}

export default About
